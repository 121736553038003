import StornoButton from './StornoButton';
import { Trans } from 'next-i18next';
import React from 'react';
import { useBookingPrice } from '@web/shop-logic';
import Price from '@/components/atoms/Price';
import RoutePlannerItem from '@/components/routePlanner/RoutePlannerItem';
import useCurrentBooking from '@/hooks/useCurrentBooking';

const RoutePlanner: React.FC = () => {
  const { totalPrice } = useBookingPrice();
  const { booking, bookingItem } = useCurrentBooking();

  return booking?.there ? (
    <div className="sticky flex flex-col items-center lg:top-2 sm:bottom-0 sm:mt-2 sm:pt-0">
      <div className="flex flex-col w-full p-2 border rounded-sm sm:px-0 border-neutral-gray3">
        <div className="flex items-center justify-between sm:px-2">
          <h2 className="pt-1 pl-1 h2 sm:hidden">
            <Trans i18nKey="route.planning" />
          </h2>
          <StornoButton />
        </div>
        <div className="sm:mx-1.5">
          {!!booking?.there && <RoutePlannerItem direction="there" booking={booking.there} />}
          {!!booking?.back && (
            <>
              <hr className="my-1 border-neutral-gray3" />
              <RoutePlannerItem direction="back" booking={booking.back} />
            </>
          )}
        </div>
        {/* Skryt celkovou cenu u vyberu tarifu */}
        {(!!totalPrice || bookingItem?.bookingState !== 'ROUTE_SELECTED') && (
          <>
            <hr className="my-2 border-neutral-gray3 lg:-mx-2" />
            <div className="px-2 py-1.5 flex justify-between items-center rounded-sm bg-neutral-graylight sm:mx-2">
              <span className="font-bold">
                <Trans i18nKey="payments.modal.summary.totalPrice" />
                <span className="sr-only">
                  <Price price={totalPrice} />
                </span>
              </span>
              <h3 aria-hidden className="h3 sm:text-h2 sm:text-primary-blue">
                <Price price={totalPrice} />
              </h3>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};
export default RoutePlanner;

import ClickableDiv from '../atoms/ClickableDiv';
import Price from '../atoms/Price';
import useModal from '../modal/useModal';
import DiscountModal, { DiscountFormProps } from './DiscountModal';
import { Trans, useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { FormikProps } from 'formik';
import { useHeaders } from '@web/shop-logic';
import { useAllowDiscountModal } from '@/hooks/useAllowDiscountModal';
import useCurrentBooking from '@/hooks/useCurrentBooking';

interface DiscountModalButtonProps {
  direction?: 'there' | 'back';
}

const DiscountModalButton: React.FC<DiscountModalButtonProps> = ({ direction }) => {
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { booking } = useCurrentBooking();
  const allowDiscountModal = useAllowDiscountModal(direction);
  const { currency } = useHeaders();

  const formRef = useRef<FormikProps<DiscountFormProps>>(null);
  const onFormReset = () => formRef.current?.resetForm();

  const onShowDiscountModal = () =>
    showModal(<DiscountModal ref={formRef} back={direction === 'back'} />, {
      title: t('route.percentualDiscount.info'),
      unmountOnClose: true,
      onClose: onFormReset,
    });

  const currentDirectionBooking = direction === 'back' ? booking?.back : booking?.there;

  if (!!currentDirectionBooking?.discountAmount) {
    return (
      <div className="flex justify-between items-center mt-1 gap-0.5 text-14 text-secondary-green">
        <Trans i18nKey="route.discountApplied" />

        <p className="font-bold">
          {'-'}
          <Price customCurrency={currency} price={currentDirectionBooking.discountAmount} />
        </p>
      </div>
    );
  }

  if (allowDiscountModal) {
    return (
      <ClickableDiv
        className="flex items-center gap-0.5 text-14 mt-1 text-neutral-gray underline hover:underline"
        onClick={onShowDiscountModal}
      >
        <Trans i18nKey="route.applyDiscount" />
      </ClickableDiv>
    );
  }

  return null;
};

export default DiscountModalButton;

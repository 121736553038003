import useCurrentBooking from './useCurrentBooking';
import { type BookingState, BookingStates } from '@/constants/booking';

const bookingStates: Set<BookingState> = new Set([
  BookingStates.CLASS_SELECTED,
  BookingStates.ADDONS_SELECTED,
  BookingStates.SEAT_SELECTED,
]);

/**
 * @param {string} direction This is not direction from useCurrentBooking business-logic hook
 * this is just an indicator if this hook is used in back or there route planning card.
 */
export const useAllowDiscountModal = (direction: 'back' | 'there'): boolean => {
  const { booking } = useCurrentBooking();

  const directionBooking = direction === 'back' ? booking?.back : booking?.there;

  /**
   * Checks if the discount modal is in one of the steps where ticket price & class is selected,
   * without those parameters we can't calculate the amount to subtract from the total price.
   */
  const allowDiscountModal = bookingStates.has(directionBooking?.bookingState);

  return allowDiscountModal;
};
